@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Kalam:400,700');

html,
body {
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

h1.aph.support,
h2.aph.support,
h3.aph.support,
h4.aph.support,
h5.aph.support,
h6.aph.support {
    font-family: "Kalam", cursive;
}
